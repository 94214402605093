$backgroundHi : #f3f3f3;
$mid : #B5B5B5;
body {
    margin: 0;
    padding: 0;
    //font-family: sans-serif;
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  // @import url('https://fonts.googleapis.com/css?family=Anton|Roboto+Mono|Audiowide|Exo|Fjalla+One|Teko&display=swap');
  @import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic|Fjalla+One);
  
  ::placeholder {
    color: $mid;
    }

/*
Neusa Next Std Bold

font-family: neusa-next-std, sans-serif;

font-weight: 700;

font-style: normal;


Neusa Next Std Italic

font-family: neusa-next-std, sans-serif;

font-weight: 400;

font-style: italic;


Neusa Next Std Thin

font-family: neusa-next-std, sans-serif;

font-weight: 250;

font-style: normal;


Neusa Next Std Bold Italic

font-family: neusa-next-std, sans-serif;

font-weight: 700;

font-style: italic;


Neusa Next Std Light

font-family: neusa-next-std, sans-serif;

font-weight: 300;

font-style: normal;


Neusa Next Std Regular

font-family: neusa-next-std, sans-serif;

font-weight: 400;

font-style: normal;


Neusa Next Std Compact Bold Italic

font-family: neusa-next-std-compact, sans-serif;

font-weight: 700;

font-style: italic;


Neusa Next Std Compact Regular

font-family: neusa-next-std-compact, sans-serif;

font-weight: 400;

font-style: normal;


Neusa Next Std Compact Italic

font-family: neusa-next-std-compact, sans-serif;

font-weight: 400;

font-style: italic;


Neusa Next Std Compact Bold

font-family: neusa-next-std-compact, sans-serif;

font-weight: 700;

font-style: normal;


Neusa Next Std Condensed Bold Italic

font-family: neusa-next-std-condensed, sans-serif;

font-weight: 700;

font-style: italic;


Neusa Next Std Condensed Regular

font-family: neusa-next-std-condensed, sans-serif;

font-weight: 400;

font-style: normal;


Neusa Next Std Condensed Italic

font-family: neusa-next-std-condensed, sans-serif;

font-weight: 400;

font-style: italic;


Neusa Next Std Condensed Bold

font-family: neusa-next-std-condensed, sans-serif;

font-weight: 700;

font-style: normal;


Neusa Next Std Wide Bold Italic

font-family: neusa-next-std-wide, sans-serif;

font-weight: 700;

font-style: italic;


Neusa Next Std Wide Italic

font-family: neusa-next-std-wide, sans-serif;

font-weight: 400;

font-style: italic;


Neusa Next Std Wide Regular

font-family: neusa-next-std-wide, sans-serif;

font-weight: 400;

font-style: normal;


Neusa Next Std Wide Bold

font-family: neusa-next-std-wide, sans-serif;

font-weight: 700;

font-style: normal;


 


*/
body {
    font-family: sans-serif;
}




.Helm3 {
    background: $backgroundHi ;
    min-height: 100vh;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-style: normal;
}

